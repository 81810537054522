<template>
  <div>
    <!--1 背景图 -->
    <bigbg url="static/srbanner/Y1bDWUXM.webp" bigtext="电子商城" smalltext="市场全球化，交易快捷化，成本低廉化"></bigbg>
    <!-- 2。文字 -->
    <div class="twotext">
      <div class="textone">多前端的电商平台</div>
      <div class="texttwo">
        支持多前端的电商平台，手机和平板电脑上APP/微信商城(公众号+小程序一体化)/Web页面的良好支持，实现手机在手，随时购物的用户体验
      </div>
    </div>
    <!-- 3 图片 -->
    <div class="twobigbox">
      <div class="bigbig">
        <div class="img"></div>
        <div class="text">
          <div class="textbig">手机在线访问</div>
          <div class="textsmall">让您的客户随时随地手机在手，就能访问查看您的商品</div>
        </div>
      </div>
      <div class="bigbig">
        <div class="img"></div>
        <div class="text">
          <div class="textbig">简化注册</div>
          <div class="textsmall">轻松使用第三方登录方式，简化繁琐的注册步骤，提高客户购买率</div>
        </div>
      </div>
      <div class="bigbig">
        <div class="img"></div>
        <div class="text">
          <div class="textbig">一键分享</div>
          <div class="textsmall">展示景区内外3D电子地图，实现跨楼层/楼宇导航，导航过程带有语音、距离提示</div>
        </div>
      </div>
    </div>
    <!-- 4图片 -->
    <div class="fourbigbox">
      <div class="bigbig">
        <div class="img"></div>
        <div class="text">
          <div class="textbig">便捷支付</div>
          <div class="textsmall">系统会自动将游客周边的展品信息推送到手机上，游客可通过文字、语音以及视频信息对展品进行了解</div>
        </div>
      </div>
      <div class="bigbig">
        <div class="img"></div>
        <div class="text">
          <div class="textbig">友好互动</div>
          <div class="textsmall">以领先的3D技术为文物提供数字化保护，助力文博艺术行业实现智能化，打造信息化、高效化的智慧文博</div>
        </div>
      </div>
      <div class="bigbig">
        <div class="img"></div>
        <div class="text">
          <div class="textbig">统一管理</div>
          <div class="textsmall">以数字大屏为基础，结合导航技术通过图片、音频、视频对场馆进行360°展示</div>
        </div>
      </div>
    </div>
    <!-- 5 背景图片-->
    <div class="fivebigbox">
      <div class="fievetextone">商城系统</div>
      <div class="fivetexttwo">重安全保障系统稳定，高性能提供极致体验</div>
      <div class="fiveimgbox">
        <!-- 1 -->
        <div class="fiveonebigbox">
          <div class="fiveonebox">
            <div class="leftimg"></div>
            <div class="righttext">
              <div class="textfiveyi">单商户</div>
              <div class="textfiveer">成功稳定的单商户零售，批发系统，支持二次开发定制，让企业用更低的成本、更少的人力、更快的速度构建自己的品牌商城</div>
            </div>
          </div>
          <div class="fiveonebox">
            <div class="leftimg"></div>
            <div class="righttext">
              <div class="textfiveyi">多商户</div>
              <div class="textfiveer">支持平台自营+商家入驻的经营模式，支持多商圈线上线下联营模式</div>
            </div>
          </div>
          <div class="fiveonebox">
            <div class="leftimg"></div>
            <div class="righttext">
              <div class="textfiveyi">多门店</div>
              <div class="textfiveer">支持门店配送与定位相结合的形式，线上线下数据打通，实现门店平台化管理的新零售模式</div>
            </div>
          </div>
        </div>
        <!-- 2 -->
        <div class="imgfive"></div>
        <!-- 3 -->
        <div class="fivetwobigbox">
          <div class="fiveonebox">

            <div class="righttext">
              <div class="textfiveyi">全终端</div>
              <div class="textfiveer">PC+H5+APP全渠道小程序，抢占全网流量</div>
            </div>
            <div class="leftimg"></div>
          </div>
          <div class="fiveonebox">

            <div class="righttext">
              <div class="textfiveyi">全场景</div>
              <div class="textfiveer">支持各种业务场景实现，社交分销、企业内购、积分商城、直播电商、跨境电商…</div>
            </div>
            <div class="leftimg"></div>
          </div>
          <div class="fiveonebox">

            <div class="righttext">
              <div class="textfiveyi">全行业</div>
              <div class="textfiveer">支持满足各垂直行业的数字化转型需求，打造具备行业特点的线上交易平台</div>
            </div>
            <div class="leftimg"></div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <!-- 6 zuihou 823px -->
    <div class="sixbigbox">
      <div class="onetextbig">系统性能保障</div>
      <div class="twotextbig">重安全保障系统稳定，高性能提供极致体验</div>
      <div class="siximgbox">
        <div class="sixbigboxs">
          <div class="imgsixs"></div>
          <div class="textsix">
            <div class="textsixb">高性能</div>
            <div class="textsixs">订单并发数实测值50000单/分，商品全文检索秒级响应，高性能提供极致用户体验</div>
          </div>
        </div>
        <div class="sixbigboxs">
          <div class="imgsixs"></div>
          <div class="textsix">
            <div class="textsixb">重安全</div>
            <div class="textsixs">安全开发规范，代码安全审计，主机安全扫描，应用安全渗透测试</div>
          </div>
        </div>
        <div class="sixbigboxs">
          <div class="imgsixs"></div>
          <div class="textsix">
            <div class="textsixb">多测试</div>
            <div class="textsixs">从功能测试、性能测试到安全测试等完整的系统测试流程，保证系统正常稳定上线运行</div>
          </div>
        </div>
        <div class="sixbigboxs">
          <div class="imgsixs"></div>
          <div class="textsix">
            <div class="textsixb">重产研</div>
            <div class="textsixs">占比90%的产研团队，深研最新开发技术，紧跟互联网的高速迭代，为客户提供最安全、稳定的系统</div>
          </div>
        </div>

      </div>

    </div>
    <!-- 5底部 -->
    <Header></Header>
    <Footer></Footer>
  </div>
</template>
<script>
import bigbg from "../component/bigbg.vue";
// 导入头部底部组件
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: 'e_mall',
  data () {
    return {

    }
  },
  components: {
    bigbg,
    Header,
    Footer
  }
}
</script>
<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }
  //2
  .twotext {
    padding-top: 80px;
    .textone {
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .texttwo {
      width: 1053px;
      margin: 0 auto;
      padding-top: 24px;
      font-size: 20px;
   line-height: 35px;
      font-weight: 400;
      color: #565656;
      padding-bottom: 60px;
    }
  }
  //3
  .twobigbox {
    width: 1200px;
    margin: 0 auto;
    height: 434px;
    display: flex;
    justify-content: space-between;
    .bigbig {
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      width: 380px;
      height: 434px;
      .img {
        width: 380px;
        height: 260px;
        background: url('./photo/C6LgV5Qk.webp') no-repeat center;
        background-size: 100% 100%;
      }
      .text {
        padding: 24px;
        text-align: left;
        .textbig {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 10px;
        }
        .textsmall {
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .bigbig:nth-child(2) {
      .img {
        background: url('./photo/3iK166J9.webp') no-repeat center;
        background-size: 100% 100%;
      }
    }
    .bigbig:nth-child(3) {
      .img {
        background: url('./photo/6KlaRPjk.webp') no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
  //4
  .fourbigbox {
    margin-top: 54px !important;
    margin-bottom: 100px !important;
    width: 1200px;
    margin: 0 auto;
    height: 434px;
    display: flex;
    justify-content: space-between;
    .bigbig {
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      width: 380px;
      height: 434px;
      .img {
        width: 380px;
        height: 260px;
        background: url('./photo/joPXbyf0.webp') no-repeat center;
        background-size: 100% 100%;
      }
      .text {
        padding: 24px;
        text-align: left;
        .textbig {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 10px;
        }
        .textsmall {
          font-size: 20px;
       
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .bigbig:nth-child(2) {
      .img {
        background: url('./photo/kB7yQKGB.webp') no-repeat center;
        background-size: 100% 100%;
      }
    }
    .bigbig:nth-child(3) {
      .img {
        background: url('./photo/Q14g0ELV.webp') no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
  //5
  .fivebigbox {
    height: 876px;
    background: #f6f8fb;
    padding-top: 80px;
    .fievetextone {
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .fivetexttwo {
      padding-top: 24px;
      font-size: 20px;
   
      font-weight: 400;
      color: #565656;
      padding-bottom: 60px;
    }
    .fiveimgbox {
      margin: 0 auto;
      width: 1168px;
      display: flex;
      .imgfive {
        margin-top: 62px;
        width: 204px;
        height: 422px;
        background: url('./photo/imgfives.png') no-repeat center;
        background-size: 100% 100%;
      }
      .fiveonebigbox {
        width: 482px;
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .fiveonebox {
          height: 124px;
          display: flex;
          .leftimg {
            width: 68px;
            height: 68px;
            background: url('./photo/ser.png') no-repeat center;
            background-size: 100% 100%;
          }
          .righttext {
            text-align: left;
            padding-left: 16px;
            width: 264px;
            .textfiveyi {
              font-size: 22px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            .textfiveer {
              font-size: 16px;
                padding-top:15px;
              line-height: 25px;
              font-weight: 400;
              color: #999999;
            }
          }
        }
        .fiveonebox:nth-child(1) {
          padding-left: 80px;

          .leftimg {
            background: url('./photo/syi.png') no-repeat center;
            background-size: 100% 100%;
          }
        }
        .fiveonebox:nth-child(3) {
          padding-left: 80px;

          .leftimg {
            background: url('./photo/ssan.png') no-repeat center;
            background-size: 100% 100%;
          }
        }
      }

      .fivetwobigbox {
        width: 482px;
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .fiveonebox {
          height: 124px;
          display: flex;
          .leftimg {
            width: 68px;
            height: 68px;
            background: url('./photo/ser.png') no-repeat center;
            background-size: 100% 100%;
          }
          .righttext {
            text-align: left;
            padding-left: 16px;
            width: 264px;
            .textfiveyi {
              font-size: 22px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            .textfiveer {
              font-size: 16px;
              padding-top:15px;
             line-height: 25px;
              font-weight: 400;
              color: #999999;
            }
          }
        }
        .fiveonebox:nth-child(1) {
          padding-left: 80px;

          .leftimg {
            background: url('./photo/ssi.png') no-repeat center;
            background-size: 100% 100%;
          }
        }
        .fiveonebox:nth-child(3) {
          padding-left: 80px;

          .leftimg {
            background: url('./photo/sliu.png') no-repeat center;
            background-size: 100% 100%;
          }
        }
        .fiveonebox:nth-child(2) {
          padding-left: 150px;

          .leftimg {
            background: url('./photo/swu.png') no-repeat center;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  //6
  .sixbigbox {
    height: 823px;
    padding-top: 80px;
    .onetextbig {
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .twotextbig {
      font-size: 20px;
   
      font-weight: 400;
      color: #565656;
      padding-top: 24px;
      padding-bottom: 60px;
    }
    .siximgbox {
      height: 316px;
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: space-between;
      .sixbigboxs {
        width: 278px;
        box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
        padding-top: 48px;
        .imgsixs {
          width: 76px;
          height: 76px;
          background: url('./photo/sixss.png') no-repeat center;
          background-size: 100% 100%;
          margin: 0 auto;
        }
        .textsix {
          .textsixb {
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            padding-top: 24px;
          }
          .textsixs {
            font-size: 14px;
            line-height: 25px;
            font-weight: 400;
            color: #999999;
            padding-top: 16px;
            padding-left: 36px;
            padding-right: 36px;
          }
        }
      }
    }

    .sixbigboxs:nth-child(2) {
      .imgsixs {
        background: url('./photo/sixsss.png') no-repeat center;
        background-size: 100% 100%;
      }
    }
    .sixbigboxs:nth-child(3) {
      .imgsixs {
        background: url('./photo/sevenss.png') no-repeat center;
        background-size: 100% 100%;
      }
    }
    .sixbigboxs:nth-child(4) {
      .imgsixs {
        background: url('./photo/sevensss.png') no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
  //7
</style>