<template>
  <div>
    <!-- 头部横幅 -->
    <!--v-lazy:background-image="url" :style="{'background-image' : 'url(' +url + ')'}" -->
    <div class="banner" >
      <!-- 横幅内容区域 -->
      <img v-lazy="url">
    <div>
        <h2 class="title">{{ bigtext }}</h2>
      <p class="introduce">
        {{ smalltext }}
      </p>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bigbg",
  props: {
    url: {
      required: true,
    },
    bigtext: {
      required: true,
    },
    smalltext: {
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.banner {
  height: 600px;
  width: 100%;
  color: #fff;
  font-family: PingFangSC-Medium, PingFang SC;
  // background-image: url("../trapoer/photo/bigbg.jpg");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-position: top 0;
  position: relative;
   img[lazy="loading"] {
    margin-top: 150px;
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
	height: 600px;
  width: 100%;
	}
  div{
 .title {
    width: 412px;
    height: 67px;
    font-weight: 500;
    line-height: 67px;
    letter-spacing: 3px;
    position: absolute;
    left: 330px;
    top: 230px;
    font-size: 48px;
    font-weight: 500;
    color: #fff;
    text-align: left;
  }
  .introduce {
    width: 697px;
    height: 66px;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 330px;
    top: 317px;
  }
  }
 
}
.introduce,
.title {
  animation: sport 0.5s;
  transform: translateY(0px);
  width: 300px;
  height: 80px;
}
//设置头部文字动画效果
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
